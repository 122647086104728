<template>
  <div class="box">
    <div class="img">
       <img src="@/assets/images/404.png" alt="">
       nihao
    </div>
    <div class="tip" >
        <p>页面哦豁了(っ °Д °;)っ</p>
        <p>请联系管理员</p>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { Button } from 'vant';
Vue.use(Button);
export default {
  data() {
    return {
    }
  },
  created() {
    
  },
  methods: {
  },
}
</script>

<style lang="less" scoped>
  .box {
    height: 90vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;
    font-size: 16px;
    background: #f4f9ff;
    padding-top: 100px;
    .img {
      width: 200px;
      img {
        width: 100%;
      }
    }
    .tip {
      width: 200px;
      border-radius: 18px;
      background: linear-gradient(90deg, #6a57fb8e, #7c9afcb4);
      text-align: center;
      line-height: 16px;
      font-weight: 600;
      padding: 0 16px;
    }
  }
</style>